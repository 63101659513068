<template>
  <div class="auth-wrapper auth-v2 pa-0">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/illustration-forgot-password-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    <!-- Forgot Password? 🔒 -->
                    {{ $t('ForgotPassword.forgot_password') }}
                  </p>
                  <p class="mb-2">
                    <!-- Enter your email and we'll send you instructions to reset your password -->
                    {{ $t('ForgotPassword.forgot_password_description') }}
                  </p>
                </v-card-text>

                <!-- Forget form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="username"
                      outlined
                      :label="$t('ForgotPassword.form.username')"
                      :placeholder="$t('ForgotPassword.form.username_placeholder')"
                      hide-details="auto"
                      :error-messages="errorMessages.username"
                      :rules="[required]"
                      :disabled="dismissCountDown > 0"
                    ></v-text-field>

                    <v-btn
                      class="mt-4"
                      block
                      color="primary"
                      type="submit"
                      :disabled="dismissCountDown > 0"
                    >
                      <div v-if="loading">
                        <v-progress-circular
                          indeterminate
                          color="red"
                          @click.prevent="onCancelRequest"
                        >
                          <v-icon>
                            {{ icons.mdiClose }}
                          </v-icon>
                        </v-progress-circular>
                      </div>
                      <!-- Send reset link -->
                      <span v-else>{{ $t('ForgotPassword.form.submit') }}</span>
                    </v-btn>
                  </v-form>

                  <!-- count down dismissible alert  -->
                  <div>
                    <v-alert
                      v-if="dismissCountDown > 0"
                      v-height-fade.appear
                      fade
                      type="success"
                      class="mb-0 mt-2"
                    >
                      <div class="alert-body">
                        <span>The reset email expired in {{ dismissCountDown }} seconds...</span>
                      </div>
                    </v-alert>
                    <!-- <span>{{ refreshToken }}</span> -->
                    <router-link
                      v-if="refreshToken"
                      :to="{ name:'auth-reset-password', query:{ token: refreshToken } }"
                    >
                      Test it!!
                    </router-link>
                  </div>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>{{ $t('ForgotPassword.back_to_login') }}</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'

import { required } from '@core/utils/validation'

// Services
import useJwt from '@/services/api/modules/auth/useJwt'
import useAuthHandler from '@/views/pages/auth/useAuthHandler'

export default {
  setup() {
    // Uses
    const { sendAuthNotification } = useAuthHandler()

    // Data
    const username = ref(localStorage.getItem('username'))
    const errorMessages = ref([])
    const isPasswordVisible = ref(false)
    const dismissCountDown = ref(0)
    const refreshToken = ref('')

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onResetPasswordRequest()

      // onResetPasswordRequest2()
      //   .then(response => {
      //     sendAuthNotification(response.message, 'success')
      //     dismissCountDown.value = 600
      //     refreshToken.value = 'kkdelavaka'
      //   })
      //   .catch(error => {
      //     sendAuthNotification(error.message, 'error')
      //     errorMessages.value = error.error
      //   })
    }

    // eslint-disable-next-line no-unused-vars
    const onResetPasswordRequest2 = () => new Promise((resolve, reject) => {
      loading.value = !loading.value
      errorMessages.value = []

      // ok
      resolve()

      // Error
      // const error = {
      //   message: 'Check your email',
      //   error: {
      //     username: 'Check your email',
      //   },
      // }
      // reject(error)
    })
    const onResetPasswordRequest = () => {
      useJwt.resetPasswordRequest({
        username: username.value,
      })
        .then(response => {
          // Solo devolvemos el token en modo test
          const { accessToken } = response.data
          refreshToken.value = accessToken

          // Informamos durante 10 minutos (Duración del token)
          dismissCountDown.value = 600
          sendAuthNotification(response.message, 'success')
        })
        .catch(error => {
          dismissCountDown.value = 0

          if (error.response && error.response.data && error.response.data.error) {
            errorMessages.value = error.response.data.error

            sendAuthNotification(error.response.data.error.message, 'error')
          }
        })
    }
    const onCancelRequest = () => new Promise(resolve => {
      loading.value = false
      resolve()
    })

    return {
      // Form validation
      form,
      handleFormSubmit,
      required,

      // Data
      username,
      errorMessages,
      isPasswordVisible,
      dismissCountDown,
      refreshToken,

      // Interface events
      loading,
      onCancelRequest,
      onResetPasswordRequest, // No se usa en el Template

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '@core/preset/preset/pages/auth.scss';
</style>
